import React from 'react'
import Packages from '../components/Packages'
import MainLayout from '../components/MainLayout'

const PackagesPage = () => {
  return (
    <MainLayout>
      <Packages />
    </MainLayout>
  )
}

export default PackagesPage
