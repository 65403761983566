import styled from 'styled-components'

const PricingHeadline = styled.h4`
  display: block;
  font-weight: bold;
  font-size: 1.5rem;
  color: ${ ({ theme }) => theme.denim };
  margin: 0 0 1.6875rem 0;
`
export default PricingHeadline
