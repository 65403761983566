import React from 'react'
import styled from 'styled-components'
import { Row, Col } from 'react-styled-flexboxgrid'
import { Primary, Alternate, Secondary } from '../../components/Section'
import PackagesHero from './PackagesHero'
import DivorcePackage from '../DivorcePackage'
import { BackgroundSectionStyle } from '../Section/backgroundSection.css.js'
import { calculateRem } from '../../utils/javascript-styles'

const StyledPackagesHero = styled(PackagesHero)`
  ${BackgroundSectionStyle};
  padding-top: 10rem;
  min-height: ${props => props.backgroundHeight}px;
  text-align: ${props => (props.centered ? 'center' : 'initial')};
  h1,
  h2,
  h3 {
    color: ${props => props.theme.white};
  }
`
const Shadowed = styled.h1`
  background: rgba(0, 0, 0, 0.5);
`

const Packages = () => {
  return (
    <section>
      <StyledPackagesHero backgroundHeight={550} centered>
        <Row center="md">
          <Col xs={12} md={8}>
            <Shadowed>MJMD Mediation, Coaching, & Sports Management</Shadowed>
          </Col>
        </Row>
      </StyledPackagesHero>

      <Primary>
        <Row>
          <Col xs={12}>
            <h2>
              Take a look at our online packages. Each package starts with a
              free consultion.
            </h2>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <DivorcePackage title="Divorce Coaching (6 months)" price={5000}>
              <p>
                High Conflict Mediated Divorces: can take a lot longer than
                expected. A coaching plan may be beneficial. In addition to
                mediation, this package also includes time with an Expert
                Divorce Coach for up to 6 months. We make sure that you are not
                alone during this process of divorce. We understand that divorce
                causes a big change in your life as well as in the lives of
                members of your family. We go through the process with you.
              </p>
              <p>
                We understand that many of our clients are high profile with
                very busy schedules. We stay focused on your goals. We know that
                the court system wants your money. Your soon-to-be former spouse
                wants your money. And fortunately, we know that if you have kids
                they want and need your time. Therefore, we become part of your
                team. Save time. Save money and move forward towards having a
                healthy co-parenting relationship.
              </p>
            </DivorcePackage>

            <DivorcePackage
              title="Divorce Coaching (1 month)"
              customPrice="$1,000"
            >
              <p>
                This package gets you started in your journey with divorce. We
                make sure that you are not alone during this process of divorce.
                We understand that divorce causes a big change in your life as
                well as in the lives of members of your family. We help you with
                the foundation needed in your journey.
              </p>
              <p>
                We understand that many of our clients are high profile with
                very busy schedules. We stay focused on your goals. We know that
                the court system wants your money. Your soon-to-be former spouse
                wants your money. And fortunately, we know that if you have kids
                they want and need your time. Therefore, we become part of your
                team. Save time. Save money and move forward towards having a
                healthy relationship.
              </p>
            </DivorcePackage>
            <DivorcePackage title="Mediation (2 months)" customPrice="$2,500">
              <p>
                We assist in preparing the Marital Settlement Agreement,
                Parenting Plan, and Child support guideline worksheets. These
                worksheets should save you time and money with your attorney.
                This is a 60-day service. When the settlement agreement is
                completed we continue to provide services within that time
                period. We do this to assist in the continuity of the agreement
                with an emphasis on co-parenting.
              </p>
              <h4>Other Coaching Services Such as:</h4>
              <ul>
                <li>Before Marriage Coaching</li>
                <li>During Marriage Coaching</li>
                <li>After Marriage Coaching</li>
                <li>
                  Bimonthly 60-90 Minute Coaching Session:
                  <ul>
                    <li>Stay on track with your career no matter what.</li>
                    {/* <li>$395 per month</li> */}
                  </ul>
                </li>
              </ul>
              <p>
                Includes assessments and other tools and resources Contact
                between session by email or text.
              </p>
            </DivorcePackage>
          </Col>
        </Row>
      </Primary>
    </section>
  )
}

export default Packages
